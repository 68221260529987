import styled from '@emotion/styled'

const ButtonBlueOutline = styled.a`
  margin: 1rem 0;
  padding: 0.5rem 1.5rem;
  color: #1e14af;
  border-radius: 30px;
  border: 1px solid #1e14af;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`

export default ButtonBlueOutline
