import { NewsCard, PrismicAllNews } from '@/components/generic/NewsCard'
import Layout from '@/components/layouts/Layout'
import ButtonBlueOutline from '@/components/styled/ButtonBlueOutline'
import { H1 } from '@/components/styled/Typography'
import { formatDateToJA } from '@/utils/dates/formatDateToJA'
import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { useState } from 'react'

const Heading = styled(H1)``

export const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.75rem;
  margin-bottom: 4rem;

  ${breakpoint} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadMore = styled(ButtonBlueOutline)`
  margin-bottom: 5rem;
`

export default function News({ data }: { data: PrismicAllNews }) {
  const [toDisplay, setToDisplay] = useState(6)

  if (!data) {
    return <div>Loading news...</div>
  }

  const allNewsDisplayed = data.allPrismicNew.edges.length >= toDisplay

  return (
    <Layout>
      <PageWrapper>
        <Heading>メディア掲載情報</Heading>
        <NewsWrapper>
          {data.allPrismicNew.edges.slice(0, toDisplay).map(({ node }) => (
            <NewsCard
              heading={node.data.heading.text}
              subheading={node.data.subheading.text}
              date={formatDateToJA(node.data.date)}
              excerpt={node.data.excerpt.text}
              link={node.data.link.url}
            />
          ))}
        </NewsWrapper>
        {allNewsDisplayed && (
          <LoadMore onClick={() => setToDisplay((prev) => prev + 6)}>
            Load More
          </LoadMore>
        )}
      </PageWrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicNew(sort: { fields: [data___article_date], order: DESC }) {
      edges {
        node {
          data {
            heading {
              text
            }
            subheading {
              text
            }
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
