import styled from '@emotion/styled'
import React from 'react'

export interface New {
  heading: string
  subheading: string
  date: string
  excerpt: string
  link: string
}

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 2px 8px 10px rgba(239, 239, 250, 0.4);
  border-radius: 6px;
  padding: 1.5rem;
`

const Heading = styled.div`
  margin: 0;
  text-transform: uppercase;
  color: #1e14af;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
`

const SubHeading = styled.h3`
  margin-bottom: 0.5rem;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #020247;
`

const Date = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #726f90;
`

const Excerpt = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #726f90;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`

const Link = styled.a`
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  color: #ff0030;
`

export function NewsCard({
  heading,
  subheading,
  date,
  excerpt,
  link
}: New): JSX.Element {
  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      <SubHeading>{subheading}</SubHeading>
      <Date>{date}</Date>
      <Excerpt>{excerpt}</Excerpt>
      <Link href={link} target="_blank">
        READ MORE
      </Link>
    </Wrapper>
  )
}

export interface PrismicAllNews {
  allPrismicNew: {
    edges: [
      {
        node: {
          data: {
            heading: {
              text: string
            }
            subheading: {
              text: string
            }
            date: string
            excerpt: {
              text: string
            }
            link: {
              url: string
            }
          }
        }
      }
    ]
  }
}
